// If you want to override variables do it here
@import "variables";

$enable-ltr: true; /* stylelint-disable-line scss/dollar-variable-default */
$enable-rtl: true; /* stylelint-disable-line scss/dollar-variable-default */

// Import styles
@import "@coreui/coreui/scss/coreui";

@import "layout";


// If you want to add custom CSS you can put it here.
// @import "custom";


// $fa-font-path: "@fortawesome/fontawesome-free/webfonts";
// @import url("https://fonts.bunny.net/css?family=Nunito");


// // Datatables BS4
@import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";


// // Font Awesome
@import "@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "@fortawesome/fontawesome-free/scss/solid.scss";
@import "@fortawesome/fontawesome-free/scss/brands.scss";
@import "@fortawesome/fontawesome-free/scss/regular.scss";

// //resources/css/app.css
@import 'flatpickr/dist/flatpickr.css';

@import "~sweetalert2/src/sweetalert2";

@import "select2/dist/css/select2.css";
